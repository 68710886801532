import React from 'react';
import { useRouter } from 'next/router';
import { SectionMenu } from '@store/content';
import { HStack, VStack, Text, Box } from '@design-system/pc';
import useCategoryMenu from './hooks/useCategoryMenu';
import styled from 'styled-components';
import TrackingService from '@/services/tracking';

type CategoryMenuProps = {
  sectionMenuData?: SectionMenu | null | undefined;
  isShow?: boolean;
};

function CategoryMenu({ sectionMenuData, isShow = false }: CategoryMenuProps) {
  const {
    handleEnteredGroupCategoryNo,
    enteredGroupCategoryNo,
    handleEnteredMiddleCategoryNo,
    enteredMiddleCategoryNo,
    enteredGroupCategory,
    enteredMiddleCategory,
  } = useCategoryMenu({ sectionMenuData });

  const router = useRouter();

  return (
    <CategoryMenuWrap
      border="1px solid"
      backgroundColor="color_chip.white"
      isShow={
        isShow &&
        sectionMenuData?.groupCategoryList !== undefined &&
        sectionMenuData?.groupCategoryList.length > 0
      }
      onMouseLeave={() => {
        handleEnteredGroupCategoryNo('');
        handleEnteredMiddleCategoryNo('');
      }}
      height={531}
    >
      <HStack alignment="start" height="100%">
        <CategoryListWrap height="100%" overflowY="auto">
          {sectionMenuData?.groupCategoryList.map((item, index) => {
            return (
              <GroupCategoryItemWrap
                key={`grpCtgList-key-${index}-${item.categoryName}`}
                onMouseEnter={() =>
                  handleEnteredGroupCategoryNo(item.categoryNo)
                }
                onClick={() => router.push(item.linkUrl || '/')}
                backgroundColor={
                  enteredGroupCategoryNo === item.categoryNo
                    ? 'color_chip.primary'
                    : 'color_chip.white'
                }
                {...TrackingService.tagGTM(`mall_gnb_category_depth1_button`, {
                  'menu-value': item.categoryName,
                })}
              >
                <Text
                  variant="label_md_m"
                  color={
                    enteredGroupCategoryNo === item.categoryNo
                      ? 'color_chip.white'
                      : 'color_chip.primary'
                  }
                >
                  {item.categoryName}
                </Text>
              </GroupCategoryItemWrap>
            );
          })}
          {sectionMenuData?.menuList.map((item, index) => {
            return (
              <GroupCategoryItemWrap
                key={`grpCtgList-key-${index}-${item.menuDataNm}`}
                onMouseEnter={() =>
                  handleEnteredGroupCategoryNo(item.menuDataNm)
                }
                onClick={() => router.push(item.linkUrl || '/')}
                backgroundColor={
                  enteredGroupCategoryNo === item.menuDataNm
                    ? 'color_chip.primary'
                    : 'color_chip.white'
                }
                {...TrackingService.tagGTM(`mall_gnb_category_depth1_button`, {
                  'menu-value': item.menuDataNm,
                })}
              >
                <Text
                  variant="label_md_m"
                  color={
                    enteredGroupCategoryNo === item.menuDataNm
                      ? 'color_chip.white'
                      : 'color_chip.primary'
                  }
                >
                  {item.menuDataNm}
                </Text>
              </GroupCategoryItemWrap>
            );
          })}
        </CategoryListWrap>
        <Divider backgroundColor="color_chip.gray.100" />
        {enteredGroupCategoryNo !== '' && (
          <>
            <CategoryListWrap height="100%" overflowY="auto">
              {enteredGroupCategory?.middleCategoryList &&
                enteredGroupCategory.middleCategoryList.map((item) => {
                  return (
                    <>
                      <MiddleCategoryItemWrap
                        key={`category-menu-depth2-text-${item.categoryNo}-${item.categoryName}`}
                        onMouseEnter={() =>
                          handleEnteredMiddleCategoryNo(item.categoryNo)
                        }
                        onClick={() => router.push(item.linkUrl || '/')}
                        backgroundColor={
                          enteredMiddleCategoryNo === item.categoryNo
                            ? 'color_chip.gray.100'
                            : 'color_chip.white'
                        }
                        {...TrackingService.tagGTM(
                          `mall_gnb_category_depth2_button`,
                          {
                            'menu-value': item.categoryName,
                          },
                        )}
                      >
                        <Text
                          variant="label_md_m"
                          color={
                            enteredMiddleCategoryNo === item.categoryNo
                              ? 'color_chip.primary'
                              : 'color_chip.gray.700'
                          }
                        >
                          {item.categoryName}
                        </Text>
                      </MiddleCategoryItemWrap>
                    </>
                  );
                })}
            </CategoryListWrap>
            <Divider backgroundColor="color_chip.gray.100" />
          </>
        )}
        {enteredMiddleCategoryNo !== '' && (
          <>
            <CategoryListWrap>
              {enteredMiddleCategory?.smallCategoryList &&
                enteredMiddleCategory.smallCategoryList.map((item, index) => {
                  return (
                    <>
                      {index < 15 && (
                        <SmallCategoryItemWrap
                          key={`category-menu-depth3-${item.categoryNo}-${item.categoryName}`}
                          onClick={() => router.push(item.linkUrl || '/')}
                          {...TrackingService.tagGTM(
                            `mall_gnb_category_depth3_button`,
                            {
                              'menu-value': item.categoryName,
                            },
                          )}
                        >
                          <Text
                            variant="label_md_m"
                            color="color_chip.gray.700"
                          >
                            {item.categoryName}
                          </Text>
                        </SmallCategoryItemWrap>
                      )}
                    </>
                  );
                })}
            </CategoryListWrap>
            <CategoryListWrap>
              {enteredMiddleCategory?.smallCategoryList &&
                enteredMiddleCategory.smallCategoryList.map((item, index) => {
                  return (
                    <>
                      {index >= 15 && (
                        <SmallCategoryItemWrap
                          key={`category-menu-depth3-${item.categoryNo}-${item.categoryName}`}
                          onClick={() => router.push(item.linkUrl || '/')}
                          {...TrackingService.tagGTM(
                            `mall_gnb_category_depth3_button`,
                            {
                              'menu-value': item.categoryName,
                            },
                          )}
                        >
                          <Text
                            variant="label_md_m"
                            color="color_chip.gray.700"
                          >
                            {item.categoryName}
                          </Text>
                        </SmallCategoryItemWrap>
                      )}
                    </>
                  );
                })}
            </CategoryListWrap>
          </>
        )}
      </HStack>
    </CategoryMenuWrap>
  );
}

export default CategoryMenu;

const CategoryMenuWrap = styled(VStack)<{ isShow: boolean }>`
  position: absolute;
  top: 34px;
  left: -77px;
  display: ${({ isShow }) => (isShow ? `flex` : 'none')};
`;

const GroupCategoryItemWrap = styled(HStack)`
  cursor: pointer;
  display: flex;
  width: 216px;
  height: 34px;
  padding: 10px 24px;
  }
`;

const MiddleCategoryItemWrap = styled(HStack)`
  cursor: pointer;
  display: block;
  width: 216px;
  height: 34px;
  padding: 10px 24px;
  }
`;

const SmallCategoryItemWrap = styled(HStack)`
  cursor: pointer;
  display: block;
  width: 216px;
  height: 34px;
  padding: 10px 24px;
  &: hover {
    & > div {
      color: black;
    }
  }
`;

const CategoryListWrap = styled(VStack)`
  padding: 8px 0px;
`;

const Divider = styled(Box)`
  height: 100%;
  width: 1px;
`;
