import { theme } from '@/styles';
import RankDownIcon from '@/ui/Icons/RankDownIcon';
import RankNoneIcon from '@/ui/Icons/RankNoneIcon';
import RankUpIcon from '@/ui/Icons/RankUpIcon';
import React from 'react';
import styled from 'styled-components';

interface rankListProps {
  rankList: any;
  handleLinkOpen: (value: string) => void;
}

const RankList = ({ rankList, handleLinkOpen }: rankListProps) => {
  return (
    <Container>
      {rankList.map((item: any, index: number) => (
        <RankContainer key={item} onClick={() => handleLinkOpen(item[0])}>
          <Ranking>{index + 1}</Ranking>
          <RankStateIcon>
            {Number(item[1]) === 0 ? (
              <RankNoneIcon fill={theme.colors.neutral_colors.gray[600]} />
            ) : Number(item[1]) < 0 ? (
              <RankDownIcon fill={theme.colors.accent_colors.blue.default} />
            ) : (
              <RankUpIcon fill={theme.colors.accent_colors.red.default} />
            )}
          </RankStateIcon>
          <RankTitle>{item[0]}</RankTitle>
        </RankContainer>
      ))}
    </Container>
  );
};

export default RankList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 236px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const RankContainer = styled.div`
  width: 50%;
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${theme.colors.neutral_colors.gray[50]};
  }
`;

const Ranking = styled.div`
  width: 22px;
  height: 14px;
  text-align: center;
  color: ${theme.colors.accent_colors.primary.default};
  ${theme.typography.button.md_b};
`;
const RankStateIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
`;

const RankTitle = styled.div`
  width: 100%;
  color: ${theme.colors.accent_colors.primary.default};
  ${theme.typography.body2};
`;
