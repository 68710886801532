import { useCallback, useMemo, useState } from 'react';
import { SectionMenu } from '@store/content';

interface UseCategoryMenu {
  sectionMenuData?: SectionMenu | null | undefined;
}

function useCategoryMenu({ sectionMenuData }: UseCategoryMenu) {
  const [enteredGroupCategoryNo, setEnteredGroupCategoryNo] =
    useState<string>('');

  const [enteredMiddleCategoryNo, setEnteredMiddleCategoryNo] =
    useState<string>('');

  const handleEnteredGroupCategoryNo = useCallback((categoryNo: string) => {
    setEnteredGroupCategoryNo(categoryNo);
  }, []);

  const handleEnteredMiddleCategoryNo = useCallback((categoryNo: string) => {
    setEnteredMiddleCategoryNo(categoryNo);
  }, []);

  const enteredGroupCategory = useMemo(() => {
    if (sectionMenuData) {
      return sectionMenuData.groupCategoryList.find(
        (item) => item.categoryNo === enteredGroupCategoryNo,
      );
    } else return null;
  }, [enteredGroupCategoryNo, sectionMenuData]);

  const enteredMiddleCategory = useMemo(() => {
    if (enteredGroupCategory?.middleCategoryList) {
      return enteredGroupCategory.middleCategoryList.find(
        (item) => item.categoryNo === enteredMiddleCategoryNo,
      );
    } else {
      return null;
    }
  }, [enteredGroupCategory?.middleCategoryList, enteredMiddleCategoryNo]);

  return {
    handleEnteredGroupCategoryNo,
    enteredGroupCategoryNo,
    handleEnteredMiddleCategoryNo,
    enteredMiddleCategoryNo,
    enteredGroupCategory,
    enteredMiddleCategory,
  };
}

export default useCategoryMenu;
