import { theme } from '@/styles';
import Close16Icon from '@/ui/Icons/Close16Icon';
import React from 'react';
import { Text } from '@design-system/pc';
import styled from 'styled-components';

interface recentListProps {
  recentList: string[];
  handleLinkOpen: (value: string) => void;
  deleteCookie: (value: string) => void;
}

const RecentList = ({
  recentList,
  handleLinkOpen,
  deleteCookie,
}: recentListProps) => {
  return (
    <Container>
      {recentList.length !== 0 ? (
        recentList?.map((item: string) => (
          <ContetnContainer key={item}>
            <ContentText onClick={() => handleLinkOpen(item)}>
              <Text
                width="100%"
                isNoWrap
                variant="label_md_r"
                color="color_chip.primary"
                noWrapTextOverflow="ellipsis"
              >
                {item}
              </Text>
            </ContentText>
            <ContentIcon
              onClick={(e) => {
                e.preventDefault();
                deleteCookie(item);
              }}
            >
              <Close16Icon />
            </ContentIcon>
          </ContetnContainer>
        ))
      ) : (
        <NoneContent>최근 검색어가 없습니다.</NoneContent>
      )}
    </Container>
  );
};

export default RecentList;

const Container = styled.div`
  width: 100%;
  max-height: 416px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const ContetnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  :hover {
    background-color: ${theme.colors.neutral_colors.gray[50]};
  }
`;

const ContentText = styled.div`
  width: calc(100% - 24px);
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${theme.colors.accent_colors.primary.default};
  ${theme.typography.body2};
`;
const ContentIcon = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const NoneContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: ${theme.colors.neutral_colors.gray[600]};
  ${theme.typography.body2};
`;
