import { theme } from '@/styles';
import React from 'react';
import styled from 'styled-components';

interface autoCompleteProps {
  searchKey: string;
  autoCompleteList: string[];
  handleLinkOpen: (value: string) => void;
}

const AutoComplete = ({
  autoCompleteList,
  handleLinkOpen,
  searchKey,
}: autoCompleteProps) => {
  return (
    <Container>
      {autoCompleteList?.map((item: string, index) => (
        <Content
          key={`autoComplete-${item}-${index}`}
          onClick={() => handleLinkOpen(item)}
          dangerouslySetInnerHTML={{
            __html: item.replaceAll(searchKey, `<p>${searchKey}</p>`),
          }}
        />
      ))}
    </Container>
  );
};

export default AutoComplete;

const Container = styled.div`
  width: 100%;
  max-height: 416px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const Content = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  cursor: pointer;
  color: ${theme.colors.accent_colors.primary.default};

  ${theme.typography.body2};
  > p {
    color: ${theme.colors.accent_colors.red.default};
  }
  :hover {
    background-color: ${theme.colors.neutral_colors.gray[50]};
  }
`;
