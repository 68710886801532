import React from 'react';
import { IconProps } from './types/Icon.type';

function ArrowUp16Icon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77583 10.4244C2.54152 10.19 2.54152 9.81015 2.77583 9.57583L7.57583 4.77583C7.81015 4.54152 8.19005 4.54152 8.42436 4.77583L13.2244 9.57583C13.4587 9.81015 13.4587 10.19 13.2244 10.4244C12.99 10.6587 12.6101 10.6587 12.3758 10.4244L8.0001 6.04863L3.62436 10.4244C3.39005 10.6587 3.01015 10.6587 2.77583 10.4244Z"
        fill={props.fill ?? '#121212'}
      />
    </svg>
  );
}

export default ArrowUp16Icon;
