import { searchAxios } from '@/di-container/searchAxios';
import {
  GetXSearchAutoCompleteGateway,
  GetXSearchRankingGateway,
  GetXSearchSuggestGateway,
} from '@store/common';

export const searchGateway = () => {
  return {
    getAutoComplete: new GetXSearchAutoCompleteGateway(searchAxios),
    getSuggest: new GetXSearchSuggestGateway(searchAxios),
    getRanking: new GetXSearchRankingGateway(searchAxios),
  };
};
