import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { format, addDays } from 'date-fns';
import { parseCookies, setCookie } from 'nookies';

import { useOnClickOutside } from '@/hooks';
import { useModal } from '@/providers/ModalProvider/ModalProvider';
import { searchGateway } from '@/di-container/search/gateway/client';
import { useBraze } from '@/providers/BrazeProvider';

export interface UseGnbSearch {
  handleIsShowSearchInput: (isShow: boolean) => void;
  isWelcomePage: boolean;
}

const useGnbSearch = ({
  handleIsShowSearchInput,
  isWelcomePage,
}: UseGnbSearch) => {
  const router = useRouter();
  const { handleOpen } = useModal();
  const { getAutoComplete, getRanking, getSuggest } = searchGateway();
  const [searchKey, setSearchKey] = useState<string>('');
  const [rankList, setRankList] = useState<any>();
  const [suggestList, setSuggestList] = useState<string[]>([]);
  const [autoList, setAutoList] = useState<string[]>([]);
  const [recentList, setRecentList] = useState<string[]>([]);
  const COOKIE_SEPERATOR2 = '|';
  const COOKIE_SEPERATOR = '__';
  const HSM_RECENT_COOKIE_KEY = 'hsm_konan-recent2';

  const [state, setState] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('최근 검색어');
  const inputRef = useRef<HTMLDivElement>(null);
  const { logCustomEvent } = useBraze();

  useOnClickOutside(inputRef, () => {
    if (!isWelcomePage) {
      setState(false);
    }
    handleIsShowSearchInput(false);
  });

  // 자동완성 중복 제거
  const removeDuplicates = (array: string[][]) => {
    const uniqueSet = new Set(array.map((item) => JSON.stringify(item)));
    const uniqueArray = Array.from(uniqueSet).map((item) => JSON.parse(item));
    return uniqueArray;
  };

  const autoComplete = useCallback(
    async (value: string) => {
      // 검색어가 빈값인 경우 리턴
      if (!value || /^\s*$/.test(value)) return;
      const { suggestions } = await getAutoComplete.exec({
        term: value,
      });

      if (suggestions && suggestions.length > 0) {
        const returnValue = removeDuplicates(suggestions[0]);
        setAutoList(returnValue.map((item) => item[0]));
      }
    },
    [getAutoComplete],
  );

  const ranking = useCallback(async () => {
    const arr = await getRanking.exec({});
    if (arr) {
      setRankList(Object.values(arr));
    }
  }, [getRanking]);

  const suggest = useCallback(async () => {
    if (recentList.length === 0) return;
    const arr = await getSuggest.exec({
      term: recentList[0],
    });
    if (Object.entries(arr).length === 0) {
      return;
    }
    setSuggestList(Object.values(arr));
  }, [recentList, getSuggest]);

  const getResentCookies = useCallback(() => {
    const recentKey = parseCookies(null)?.[HSM_RECENT_COOKIE_KEY];
    if (!recentKey) return [];
    const items = unescape(recentKey).split(COOKIE_SEPERATOR);
    const result = items.map((item) => item.split(COOKIE_SEPERATOR2)[0]);
    setRecentList(result.slice(0, 10));
    return result;
  }, []);

  const setResentCookies = useCallback(
    (clickValue?: string) => {
      if (!searchKey && !clickValue) return;
      const toDay = new Date();
      const formatDate = format(toDay, 'yyyyMMdd');

      const recents = getResentCookies().filter((item) =>
        clickValue ? item !== clickValue : item !== searchKey,
      );

      recents.unshift(clickValue || searchKey);
      const value = escape(
        recents
          .map(function (text) {
            return text + COOKIE_SEPERATOR2 + formatDate;
          })

          .join(COOKIE_SEPERATOR),
      );

      setCookie(null, HSM_RECENT_COOKIE_KEY, value, {
        path: '/',
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        expires: addDays(new Date(), 365),
      });
      getResentCookies();
    },
    [getResentCookies, searchKey],
  );

  const handleLinkOpen = useCallback(
    async (keyValue: string) => {
      setResentCookies(keyValue);
      router.push({
        pathname: `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/search/search.do`,
        query: {
          term: searchKey,
          searchKey: keyValue || searchKey,
          searchTab: 'goods',
        },
      });
    },
    [searchKey, router, setResentCookies],
  );

  const handleEnter = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // 검색어가 없거나 빈 값인 경우 alert창 노출
    if (searchKey && !/^\s*$/.test(searchKey)) {
      setResentCookies();
      handleLinkOpen(searchKey);

      logCustomEvent({
        eventName: 'bz_search',
        eventProperties: {
          bz_keyword: searchKey,
          bz_date: format(new Date(), 'yyMMdd'),
        },
      });
    } else {
      handleOpen({
        modalProps: {
          type: 'alert',
          text: '검색어를 입력해 주십시오.',
        },
      });
    }
  };

  const clearCookies = () => {
    setCookie(null, HSM_RECENT_COOKIE_KEY, '', {
      path: '/',
      // domain: 'local.remodeling.hanssem.com',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      expires: addDays(new Date(), 365),
    });
    setRecentList([]);
    setSuggestList([]);
  };

  const deleteCookie = (keyValue: string) => {
    const recents = getResentCookies().filter((item) => item !== keyValue);
    const toDay = new Date();
    const formatDate = format(toDay, 'yyyyMMdd');
    const value = escape(
      recents
        .map(function (text) {
          return text + COOKIE_SEPERATOR2 + formatDate;
        })

        .join(COOKIE_SEPERATOR),
    );
    setCookie(null, HSM_RECENT_COOKIE_KEY, value, {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      expires: addDays(new Date(), 365),
    });
    setRecentList(recents);
    suggest();
  };

  useEffect(() => {
    ranking();
    getResentCookies();
    suggest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    suggest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentList]);

  useEffect(() => {
    autoComplete(searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    if (isWelcomePage) {
      setState(true);
    }
  }, [isWelcomePage, inputRef]);
  return {
    inputRef,
    recentList,
    suggestList,
    autoList,
    searchKey,
    rankList,
    state,
    tab,
    setTab,
    setState,
    clearCookies,
    setSearchKey,
    handleEnter,
    deleteCookie,
    handleLinkOpen,
  };
};

export default useGnbSearch;
