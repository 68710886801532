import { useState } from 'react';

function useMenu() {
  const [activeId, setActiveId] = useState(0);
  const [activeChipId, setActiveChipId] = useState(0);

  const handleActiveId = (tabId: number) => {
    setActiveId(tabId);
    setActiveChipId(0);
  };

  const handleActiveChipId = (chipId: number) => {
    setActiveChipId(chipId);
  };

  return {
    activeId,
    handleActiveId,
    activeChipId,
    handleActiveChipId,
  };
}

export default useMenu;
