import React from 'react';
import styled from 'styled-components';
import useScrollIsShow from '@/hooks/useScrollIsShow';

import Header from '@/ui/Header';
import Footer from '@/ui/Footer';

import { GNB_HEIGHT, HEADER_HEIGHT, LAYOUT_MAX_WIDTH } from '@/styles';

export type BottomSheetNameType = string;

export interface AppLayoutPropsType {
  content: React.ReactNode | React.ReactNode[];

  isHeaderExist?: boolean;
  isFooterExist?: boolean;
  isQuickExist?: boolean;
  quickSize?: 'm' | 'l' | 'full';

  isGnbExist?: boolean;
  isGnbArea?: boolean;
  isHeaderArea?: boolean;
  isHeaderBackgroundColor?: boolean;
  isWelcomePage?: boolean;
  layoutType?: 'full' | 'default';
  infoState?: boolean;
  bottomSheet?: React.ReactNode | React.ReactNode[];
  bottomSheetName?: BottomSheetNameType;
}

const AppLayout = ({
  content,
  isHeaderExist = true,
  isFooterExist = true,
  isQuickExist = true,
  isGnbExist = true,
  layoutType = 'default',
  quickSize = 'm',
  isGnbArea = true,
  isWelcomePage = false,
  isHeaderBackgroundColor = true,
}: AppLayoutPropsType) => {
  const { ref: headerRef } = useScrollIsShow(0.1);
  return (
    <Layout>
      {/* header 영역 */}
      {isHeaderExist && (
        <Header
          ref={headerRef}
          isGnbExist={isGnbExist}
          isWelcomePage={isWelcomePage}
        />
      )}

      {/* content 영역 */}
      <ContentBox type={layoutType}>
        <ContentWrap
          isFakeHeaderAreaExist={isHeaderBackgroundColor || !isWelcomePage}
        >
          {content}
        </ContentWrap>
      </ContentBox>

      {/* footer 영역 */}
      {isFooterExist && <Footer />}

      {/* side/quick 영역 */}
      {/* {isQuickExist && (
        <Quick
          size={quickSize}
          isHeaderAreaShow={isHeaderAreaShow}
          isGnbExist={isGnbExist}
        />
      )} */}
    </Layout>
  );
};

export default AppLayout;

const Layout = styled.div`
  position: relative;
  width: 100%;
`;

const ContentWrap = styled.div<{ isFakeHeaderAreaExist: boolean }>`
  padding-top: ${({ isFakeHeaderAreaExist }) =>
    isFakeHeaderAreaExist ? `${HEADER_HEIGHT}px` : '0px'};
`;

const ContentBox = styled.section<{ type: 'full' | 'default' }>`
  width: ${({ type }) =>
    type === 'default' ? `${LAYOUT_MAX_WIDTH}px` : '100%'};
  min-height: calc(100vh - ${HEADER_HEIGHT + GNB_HEIGHT}px);
  margin: 0 auto;
`;
