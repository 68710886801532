import React from 'react';
import { IconProps } from './types/Icon.type';

function RankNoneIcon(props: IconProps) {
  return (
    <svg
      width={props.boxSize ?? '16'}
      height={props.boxSize ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="8" width="6" height="1" fill={props.fill ?? '#121212'} />
    </svg>
  );
}

export default RankNoneIcon;
