import React from 'react';

function Close16Icon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1347 4.35348C12.33 4.54874 12.33 4.86532 12.1347 5.06058L5.06366 12.1317C4.8684 12.3269 4.55181 12.3269 4.35655 12.1317C4.16129 11.9364 4.16129 11.6198 4.35655 11.4245L11.4276 4.35348C11.6229 4.15822 11.9395 4.15822 12.1347 4.35348Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1307 12.1318C11.9354 12.3271 11.6188 12.3271 11.4235 12.1318L4.35248 5.06073C4.15722 4.86547 4.15722 4.54889 4.35248 4.35362C4.54774 4.15836 4.86432 4.15836 5.05959 4.35362L12.1307 11.4247C12.3259 11.62 12.3259 11.9365 12.1307 12.1318Z"
        fill="#121212"
      />
    </svg>
  );
}

export default Close16Icon;
