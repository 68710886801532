import React from 'react';
import {
  NewTextButton,
  VStack,
  Text,
  CI,
  HStack,
  NewBasicButton,
} from '@design-system/pc';
import Divider from '@/ui/Divider';
import QRcodeIcon from '../../Icons/QRcodeIcon';
import { AS_CENTER_CALL, HANSSEM_MALL_CALL } from '@/constants';
import { LAYOUT_MAX_WIDTH, theme } from '@/styles';
import TrackingService from '@/services/tracking';
import Link from 'next/link';
import styled from 'styled-components';

interface CenterProps {
  handleOpenNewScreen: () => void;
  handleOpenKakaoByMallCenter: () => void;
  handleOpenKakaoByASCenter: () => void;
  handleOpenBusinessInfoPopup: () => void;
  handleMoveQuickConsult: () => void;
  handleMoveMallCenter: () => void;
  handleMoveServiceCenter: () => void;
}

function Center({
  handleOpenNewScreen,
  handleOpenKakaoByMallCenter,
  handleOpenKakaoByASCenter,
  handleOpenBusinessInfoPopup,
  handleMoveQuickConsult,
  handleMoveMallCenter,
  handleMoveServiceCenter,
}: CenterProps) {
  return (
    <CenterWrap {...TrackingService.tagGTM('footer_content')}>
      <CenterTopWrap>
        <HStack space={48} alignment="start" mt="6px">
          <VStack alignment="start" space={8}>
            <HStack height={32} alignment="center">
              <CI
                variant="CI_signature_primary"
                resize
                width={189}
                height={18}
              />
            </HStack>
            <TitleBox>
              <Text variant="subTitle6" color="color_chip.gray.700">
                고객센터 이용안내
              </Text>
              <Info>
                평일 09:00 - 18:00 , 토요일 09:00 - 13:00 (일요일,공휴일 휴무)
              </Info>
            </TitleBox>
          </VStack>
          <HStack space={60}>
            <VStack alignment="start" space={16} mt="1px">
              <VStack alignment="start" space={2}>
                <NewTextButton
                  size="lg"
                  icon={{ variant: 'ic_arrow_right', align: 'right' }}
                  onClick={handleMoveMallCenter}
                  {...TrackingService.tagGTM(
                    'footer_content_hanssem_mall_request_button',
                  )}
                >
                  한샘몰 문의
                </NewTextButton>
                <HStack space={4}>
                  <Text variant="caption1" color="color_chip.gray.600">
                    상품, 배송, 사이트 이용문의
                  </Text>
                  <Text variant="caption1" color="color_chip.gray.600">
                    ({HANSSEM_MALL_CALL})
                  </Text>
                </HStack>
              </VStack>
              <NewBasicButton
                emphasis="low"
                size="md"
                icon={{ align: 'left', variant: 'ic_kakao_txt_solid' }}
                onClick={handleOpenKakaoByMallCenter}
                {...TrackingService.tagGTM(
                  'footer_content_hanssem_mall_request_kakao_button',
                )}
              >
                <Text variant="label_md_r" color="color_chip.primary">
                  카카오톡 상담
                </Text>
              </NewBasicButton>
            </VStack>
            <VStack alignment="start" space={16}>
              <VStack alignment="start" space={2}>
                <NewTextButton
                  size="lg"
                  icon={{ variant: 'ic_arrow_right', align: 'right' }}
                  onClick={handleMoveServiceCenter}
                  {...TrackingService.tagGTM('footer_content_as_center_button')}
                >
                  A/S 문의
                </NewTextButton>
                <HStack space={4}>
                  <Text variant="caption1" color="color_chip.gray.600">
                    A/S 신청 및 추가부품 문의
                  </Text>
                  <Text variant="caption1" color="color_chip.gray.600">
                    ({AS_CENTER_CALL})
                  </Text>
                </HStack>
              </VStack>
              <NewBasicButton
                emphasis="low"
                size="md"
                icon={{ align: 'left', variant: 'ic_kakao_txt_solid' }}
                onClick={handleOpenKakaoByASCenter}
                {...TrackingService.tagGTM(
                  'footer_content_as_center_kakao_button',
                )}
              >
                <Text variant="label_md_r" color="color_chip.primary">
                  카카오톡 상담
                </Text>
              </NewBasicButton>
            </VStack>
          </HStack>
        </HStack>
        <QrCodeWrap>
          <QRcodeIcon />
          <QrCodeText>앱 다운 받기</QrCodeText>
        </QrCodeWrap>
      </CenterTopWrap>
      <CenterBottomWrap>
        <CenterBottomBox>
          <CenterBottomBoxInner>
            <Info>{`㈜한샘 대표자: 김유진`}</Info>
            <Info
              style={{ marginLeft: '-10px' }}
            >{`경기도 안산시 성곡동 665`}</Info>
            <Divider
              w="1px"
              h="12px"
              bgColor={theme.colors.neutral_colors.gray[200]}
            />
            <Info>사업자등록번호 : 133-81-22865</Info>
            <Info>
              통신판매업신고 : 2010-경기안산-0512
              <InfoBtn
                onClick={handleOpenNewScreen}
                {...TrackingService.tagGTM('footer_bizinfo_button')}
              >
                <InfoBtnText>사업자정보확인</InfoBtnText>
              </InfoBtn>
            </Info>
            <Divider
              w="1px"
              h="12px"
              bgColor={theme.colors.neutral_colors.gray[200]}
            />
            <Info>개인정보보호 최고 책임자 : 전무권</Info>
            <Divider
              w="1px"
              h="12px"
              bgColor={theme.colors.neutral_colors.gray[200]}
            />
            <Info>
              한샘디자인파크
              <Link
                href="https://company.hanssem.com/customer_support/location_way/shop.do"
                passHref
              >
                <InfoBtn {...TrackingService.tagGTM('footer_location_button')}>
                  <InfoBtnText target={'_blank'}>위치보기</InfoBtnText>
                </InfoBtn>
              </Link>
            </Info>
          </CenterBottomBoxInner>
          <Info>
            KEB하나은행 구매안전서비스(채무지급보증)
            <InfoBtn
              onClick={handleOpenBusinessInfoPopup}
              {...TrackingService.tagGTM('footer_service_confirmation_button')}
            >
              <InfoBtnText>서비스 가입사실 확인</InfoBtnText>
            </InfoBtn>
          </Info>
          <Info>
            고객님은 안전거래를 위해 현금결제 시 저희 쇼핑몰에서 가입한
            KEB하나은행으로 구매안전서비스(채무지급보증)를 이용하실 수 있습니다.
          </Info>
        </CenterBottomBox>

        <Copyright>(C) Hanssem Co., Ltd. All rights reserved.</Copyright>
      </CenterBottomWrap>
    </CenterWrap>
  );
}

const CenterWrap = styled.div`
  width: ${LAYOUT_MAX_WIDTH}px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
`;

const CenterTopWrap = styled.div`
  width: 100%;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CenterBottomWrap = styled.div`
  width: 100%;
  height: 98px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const CenterBottomBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CenterBottomBoxInner = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const TitleBox = styled.div`
  width: 217px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: left;
`;

const Info = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: ${theme.colors.neutral_colors.gray[600]};
  ${theme.typography.caption1};
`;

const InfoBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 0px 6px;
  height: 20px;
  gap: 2px;
  border-radius: 2px;
  background-color: ${theme.colors.neutral_colors.gray[100]};
  border: none;
`;

const InfoBtnText = styled.a`
  color: ${theme.colors.neutral_colors.gray[600]};
  ${theme.typography.button.sm_r};
`;

const QrCodeWrap = styled.div`
  border: 1px solid;
  border-color: ${theme.colors.neutral_colors.gray[200]};
  border-radius: 4px;
  background-color: ${theme.colors.neutral_colors.white};
  padding: 4px 4px 5px 4px;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const QrCodeText = styled.div`
  text-align: center;
  color: ${theme.colors.neutral_colors.primary};
  ${theme.typography.caption1};
`;

const Copyright = styled.div`
  height: 17px;
  ${theme.typography.caption1};
  color: ${theme.colors.neutral_colors.gray[600]};
`;

export default Center;
