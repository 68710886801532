import TrackingService from '@/services/tracking';
import { theme } from '@/styles';
import React from 'react';
import styled, { css } from 'styled-components';
import useMenu from '../hooks/useMenu';

type TabTypeProps = {
  data: any;
  currentValue?: any;
  height?: string;
  onClickHandler?: (item: any, idx?: any) => void;
  gtmName?: string;
};

function Tab3({
  data,
  onClickHandler,
  currentValue,
  height,
  gtmName = '',
}: TabTypeProps) {
  const { activeId, handleActiveId } = useMenu();

  return (
    <TabWrap height={height} {...TrackingService.tagGTM(`${gtmName}`)}>
      {data.map((tab: any, idx: number) => {
        const isActive = activeId === tab.id;
        return (
          <TabButton
            key={tab.id}
            isActive={currentValue ? tab.name === currentValue : isActive}
            onClick={(e) => {
              e.preventDefault();
              onClickHandler
                ? onClickHandler(tab, idx)
                : handleActiveId(tab.id);
            }}
            {...TrackingService.tagGTM(`${gtmName}_button`, {
              'tab-value': tab.name,
            })}
          >
            {tab.text || tab.name}
          </TabButton>
        );
      })}
    </TabWrap>
  );
}

const TabWrap = styled.div<{ height?: string }>`
  width: 100%;
  height: ${({ height }) => height || '64px'};
  display: flex;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: ${theme.colors.neutral_colors.white};
  color: ${theme.colors.neutral_colors.primary};
  width: 100%;
  height: 100%;
  ${({ isActive }) =>
    isActive
      ? css`
          border-top: 2px solid transparent;
          border-bottom: 2px solid ${theme.colors.neutral_colors.primary};
          ${theme.typography.button.lg_m}
        `
      : css`
          border-top: 1px solid transparent;
          border-bottom: 1px solid ${theme.colors.neutral_colors.gray[100]};
          ${theme.typography.button.lg_r}
        `};
  &:first-child {
    border-bottom-left-radius: 2px;
  }
  &:last-child {
    border-bottom-right-radius: 2px;
  }
`;

export default Tab3;
