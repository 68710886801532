import { FOOTER_FULL_HEIGHT, theme } from '@/styles';
import React from 'react';
import styled from 'styled-components';
import Top from './ui/Top';
import Center from './ui/Center';
import Bottom from './ui/Bottom';
import Divider from '../Divider/Divider';
import useFooter from './hooks/useFooter';
import TrackingService from '@/services/tracking';

function Footer() {
  const {
    handleOpenPopup,
    handleOpenNewScreen,
    handleOpenKakaoByMallCenter,
    handleOpenKakaoByASCenter,
    setIsHover,
    handleOpenLink,
    handleOpenBusinessInfoPopup,
    handleMoveQuickConsult,
    handleMoveMallCenter,
    handleMoveServiceCenter,
    isHover,
  } = useFooter();

  return (
    <>
      <FooterLayout {...TrackingService.tagGTM('footer')}>
        <FooterWrap>
          <Top
            handleOpenPopup={handleOpenPopup}
            setIsHover={setIsHover}
            handleOpenLink={handleOpenLink}
            isHover={isHover}
          />
          <Divider bgColor={theme.colors.neutral_colors.gray[100]} h={'1px'} />
          <Center
            handleOpenNewScreen={handleOpenNewScreen}
            handleOpenKakaoByMallCenter={handleOpenKakaoByMallCenter}
            handleOpenKakaoByASCenter={handleOpenKakaoByASCenter}
            handleOpenBusinessInfoPopup={handleOpenBusinessInfoPopup}
            handleMoveQuickConsult={handleMoveQuickConsult}
            handleMoveMallCenter={handleMoveMallCenter}
            handleMoveServiceCenter={handleMoveServiceCenter}
          />
          <Bottom />
        </FooterWrap>
      </FooterLayout>
    </>
  );
}

const FooterLayout = styled.div<{}>`
  width: 100%;
  height: ${FOOTER_FULL_HEIGHT};
`;

const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.neutral_colors.gray[50]};
`;

export default Footer;
