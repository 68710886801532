import React from 'react';

function Close32Icon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8.4585"
        y="9.40039"
        width="1.33333"
        height="20"
        rx="0.666667"
        transform="rotate(-45 8.4585 9.40039)"
        fill="#121212"
      />
      <rect
        x="22.5991"
        y="8.45703"
        width="1.33333"
        height="20"
        rx="0.666667"
        transform="rotate(45 22.5991 8.45703)"
        fill="#121212"
      />
    </svg>
  );
}

export default Close32Icon;
