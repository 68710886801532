import { theme } from '@/styles';
import React from 'react';
import styled from 'styled-components';

type DividerProps = {
  w?: string;
  h?: string;
  bgColor?: string;
  my?: string;
  mt?: string;
  mb?: string;
  mx?: string;
  ml?: string;
  mr?: string;
  borderRadius?: string;
};

function Divider(props: DividerProps) {
  return <DividerWrap {...props} />;
}

const DividerWrap = styled.div<DividerProps>`
  width: ${({ w }) => w ?? '100%'};
  height: ${({ h }) => h ?? '2px'};
  background-color: ${({ bgColor }) =>
    bgColor || theme.colors.neutral_colors.primary};
  margin-top: ${({ my, mt }) => mt ?? my ?? '0px'};
  margin-bottom: ${({ my, mb }) => mb ?? my ?? '0px'};
  margin-left: ${({ mx, ml }) => ml ?? mx ?? '0px'};
  margin-right: ${({ mx, mr }) => mr ?? mx ?? '0px'};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

export default Divider;
