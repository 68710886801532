import { theme } from '@/styles';
import React from 'react';
import styled from 'styled-components';

const EmailPopup = () => {
  return (
    <Container>
      한샘은 공식적으로 허가되지 않은 이메일 무단수집을 거부합니다.
      <br />본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그
      밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시
      정보통신망법에 의해 형사 처벌됨을 유념하시기 바랍니다.
    </Container>
  );
};

export default EmailPopup;

const Container = styled.div`
  padding: 0px 40px 40px 40px;
  color: ${theme.colors.accent_colors.primary.default};
  ${theme.typography.body2};
  white-space: pre-wrap;
`;
