import { LAYOUT_MAX_WIDTH, theme } from '@/styles';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import ArrowUp16Icon from '@/ui/Icons/ArrowUp16Icon';
import { OpenLinkProps } from '../hooks/useFooter';
import ArrowDown16Icon from '@/ui/Icons/ArrowDown16Icon';
import { FAMILY_SITE_LIST, FOOTER_TOP_MENU_LIST } from '@/constants';
import TrackingService from '@/services/tracking';

interface topProps {
  handleOpenPopup: () => void;
  isHover: boolean;
  setIsHover: (value: boolean) => void;
  handleOpenLink: (value: OpenLinkProps) => void;
}

function Top({
  handleOpenPopup,
  isHover,
  setIsHover,
  handleOpenLink,
}: topProps) {
  return (
    <MenuBar {...TrackingService.tagGTM('footer_menu')}>
      {/* menu */}
      <MenuList>
        {FOOTER_TOP_MENU_LIST.map((item) =>
          item.link ? (
            <Link
              key={`footer-menu-item-${item.value}`}
              href={item.link}
              passHref
            >
              <Menu
                target={'_blank'}
                rel="noopener noreferrer"
                {...TrackingService.tagGTM('footer_menu_item_button', {
                  'menu-value': `${item.value}`,
                })}
              >
                {item.name}
              </Menu>
            </Link>
          ) : (
            <Menu
              key={`footer-menu-item-${item.value}`}
              onClick={handleOpenPopup}
              {...TrackingService.tagGTM('footer_menu_item_button', {
                'menu-value': `${item.value}`,
              })}
            >
              {item.name}
            </Menu>
          ),
        )}
      </MenuList>
      {/* family site selector */}
      <FamilySiteSelector
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        {...TrackingService.tagGTM('footer_family_site_dropdown_button')}
      >
        {!isHover ? (
          <Selected>
            <SelectorTitle>FAMILY SITE</SelectorTitle>
            <ArrowDown16Icon fill={theme.colors.neutral_colors.gray[600]} />
          </Selected>
        ) : (
          <>
            {FAMILY_SITE_LIST.map((item, idx) => (
              <DropItemWrap key={`family-site-${item.id}`}>
                <DropItem
                  onClick={() => handleOpenLink({ url: item.link })}
                  {...TrackingService.tagGTM('footer_family_site_button', {
                    'option-value': `${item.value}`,
                  })}
                >
                  {item.title}
                </DropItem>
                {idx === 0 && (
                  <ArrowUp16Icon fill={theme.colors.neutral_colors.primary} />
                )}
              </DropItemWrap>
            ))}
          </>
        )}
      </FamilySiteSelector>
    </MenuBar>
  );
}

const MenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  width: ${LAYOUT_MAX_WIDTH}px;
  height: 56px;
`;

const MenuList = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
`;

const Menu = styled.a`
  color: ${theme.colors.neutral_colors.gray[700]};
  ${theme.typography.label.md_r}
  &:nth-child(n+3):nth-child(-n+4) {
    font-weight: 600;
  }

  cursor: pointer;
`;

const FamilySiteSelector = styled.div`
  background-color: ${theme.colors.neutral_colors.white};
  border: 1px solid ${theme.colors.neutral_colors.gray[200]};
  width: 148px;
  height: 28px;
  border-radius: 2px;
  position: absolute;
  right: 0px;
  top: 14px;
  :hover {
    background-color: ${theme.colors.neutral_colors.white};
    height: auto;
  }
`;

const Selected = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 12px;
  cursor: pointer;
`;

const SelectorTitle = styled.div`
  color: ${theme.colors.neutral_colors.gray[500]};
  ${theme.typography.label.sm_r};
  cursor: pointer;
`;

const DropItemWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 12px;
  & + & {
    border-top: 1px solid ${theme.colors.neutral_colors.gray[200]};
  }
`;

const DropItem = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  color: ${theme.colors.accent_colors.primary.default};
  ${theme.typography.label.sm_r};
  :hover {
    color: ${theme.colors.accent_colors.red.default};
  }
  cursor: pointer;
`;

export default Top;
