import { LAYOUT_MAX_WIDTH, theme } from '@/styles';
import React from 'react';
import styled from 'styled-components';

function Bottom() {
  return (
    <BottmWrap>
      <InfoWrap>
        <Info>
          {
            "한샘몰 판매 상품 중 '입점브랜드상품'으로 명기된 상품의 경우, (주)한샘은 판매자가 아닌 통신판매중개자이므로 판매자로서의 책임을 부담하지 아니합니다. 해당 상품, 거래정보 및 거래에 대하여는 실제 판매자인 각 입점업체에게 책임이 있습니다."
          }
        </Info>
      </InfoWrap>
    </BottmWrap>
  );
}

const BottmWrap = styled.div`
  width: 100%;
  height: 49px;
  background-color: ${theme.colors.neutral_colors.gray[100]};
`;

const InfoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: ${LAYOUT_MAX_WIDTH}px;
  height: 100%;
`;

const Info = styled.div`
  width: ${LAYOUT_MAX_WIDTH}px;
  color: ${theme.colors.neutral_colors.gray[500]};
  ${theme.typography.caption1};
`;

export default Bottom;
