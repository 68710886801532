import React, { ElementType, forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { Combine, ElementProps } from '@/@types/utils';
import { theme } from '@/styles';

import Close32Icon from '@/ui/Icons/Close32Icon';
import Tab3 from '@/ui/Tab3';

import useGnbSearch from './hooks/useGnbSearch';
import RankList from './ui/RankList';
import SuggestList from './ui/SuggestList';
import AutoComplete from './ui/AutoComplete';
import RecentList from './ui/RecentList';
import { Box, Icongraphy, NewTextButton } from '@design-system/pc';

interface IGnbSearchInputProps {
  width?: number;
  height?: number;
  isBackgroundColor?: boolean;
  isTransparentBackground?: boolean;
  isWelcomePage?: boolean;
  isShowSearchInput?: boolean;
  handleIsShowSearchInput: (isShow: boolean) => void;
}

const TAB_LIST = [
  { id: 0, name: '최근 검색어', type: '최근 검색어' },
  { id: 1, name: '추천 검색어', type: '추천 검색어' },
  { id: 2, name: '인기 검색어', type: '인기 검색어' },
];

export const DEFAULT_ELEMENT = 'input' as const;

export type GnbSearchInputPropsType<T extends ElementType> = ElementProps<T> &
  Combine<IGnbSearchInputProps, ElementType<typeof DEFAULT_ELEMENT>> &
  InputHTMLAttributes<HTMLInputElement>;

const GnbSearchInput = <T extends React.ElementType>(
  {
    width = 360,
    height = 48,
    isBackgroundColorActive = true,
    isWelcomePage = false,
    isShowSearchInput = true,
    handleIsShowSearchInput,
    ...props
  }: GnbSearchInputPropsType<T>,
  ref: React.Ref<any>,
) => {
  const {
    inputRef,
    searchKey,
    rankList,
    suggestList,
    autoList,
    recentList,
    state,
    tab,
    setTab,
    setState,
    setSearchKey,
    handleLinkOpen,
    handleEnter,
    clearCookies,
    deleteCookie,
  } = useGnbSearch({ handleIsShowSearchInput, isWelcomePage });

  return (
    <GnbSearchInputContainer
      onSubmit={handleEnter}
      isHidden={isWelcomePage && !isShowSearchInput}
      isWelcomePage={isWelcomePage}
    >
      <InputWrap
        ref={inputRef}
        isBackgroundColorActive={isBackgroundColorActive}
        focusState={state}
        onClick={() => setState(true)}
      >
        <CustomInput
          ref={ref}
          width={width}
          focusState={state}
          value={searchKey}
          isBackgroundColorActive={isBackgroundColorActive}
          onChange={({ target }) => setSearchKey(target.value)}
          maxLength={100}
          {...props}
        />
        <SearchIconWrap onClick={handleEnter}>
          <Icongraphy
            size={28}
            resize
            variant="ic_search"
            fillColor={isBackgroundColorActive || state ? '' : 'white'}
          />
        </SearchIconWrap>
        {state && !searchKey && (
          <DropBox>
            <TabContainer>
              <Tab3
                data={TAB_LIST}
                height="48px"
                onClickHandler={(e) => setTab(e.type)}
                currentValue={tab}
              />
            </TabContainer>
            <ContentBox>
              {tab === '최근 검색어' && (
                <RecentList
                  recentList={recentList}
                  handleLinkOpen={handleLinkOpen}
                  deleteCookie={deleteCookie}
                ></RecentList>
              )}
              {tab === '인기 검색어' && (
                <RankList
                  rankList={rankList}
                  handleLinkOpen={handleLinkOpen}
                ></RankList>
              )}
              {tab === '추천 검색어' && (
                <SuggestList
                  handleLinkOpen={handleLinkOpen}
                  suggestList={suggestList}
                ></SuggestList>
              )}
            </ContentBox>
            <DropBoxFooter>
              <DropBoxFooterLeft>
                {tab === '최근 검색어' && (
                  <RefreshBtn onClick={clearCookies}>
                    <NewTextButton
                      type="button"
                      icon={{
                        align: 'right',
                        variant: 'ic_refresh',
                      }}
                      size="md"
                      color="color_chip.primary"
                    >
                      초기화
                    </NewTextButton>
                  </RefreshBtn>
                )}
              </DropBoxFooterLeft>

              <DropBoxFooterIcon
                boxSize="32px"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isWelcomePage) setState(false);
                  handleIsShowSearchInput(false);
                }}
              >
                <Close32Icon />
              </DropBoxFooterIcon>
            </DropBoxFooter>
          </DropBox>
        )}
        {state && searchKey && autoList.length > 0 && (
          <DropAutoBox>
            <AutoComplete
              searchKey={searchKey}
              autoCompleteList={autoList}
              handleLinkOpen={handleLinkOpen}
            />
            <DropBoxFooter>
              <DropBoxFooterLeft></DropBoxFooterLeft>

              <DropBoxFooterIcon
                boxSize="32px"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isWelcomePage) setState(false);
                  handleIsShowSearchInput(false);
                }}
              >
                <Close32Icon />
              </DropBoxFooterIcon>
            </DropBoxFooter>
          </DropAutoBox>
        )}
      </InputWrap>
    </GnbSearchInputContainer>
  );
};

export default forwardRef(GnbSearchInput) as typeof GnbSearchInput;

interface CustomInputPropsType {
  width?: number;
  height?: number;
  focusState: boolean;
  isBackgroundColorActive: boolean;
}

interface InputWrapPropsType {
  focusState: boolean;
  isBackgroundColorActive: boolean;
}

const GnbSearchInputContainer = styled.form<{
  isHidden: boolean;
  isWelcomePage: boolean;
}>`
  ${(props) => `
    position: ${props.isWelcomePage ? 'absolute' : 'relative'};
    min-width: 360px;
    max-width: 360px;
    width: 360px;
    top: ${props.isWelcomePage ? '-9px' : ''};
    right: ${props.isWelcomePage ? '14px' : ''};
    visibility: ${props.isHidden ? `hidden` : 'visible'};
  `}
`;

const DropBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
`;

const DropAutoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrap = styled(Box)<InputWrapPropsType>`
  ${(props) => `
  position: absolute;
  width: 358px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 582px;
  box-sizing: content-box;
  overflow: hidden;
  background-color : ${
    props.isHeaderBackgroundColor || props.focusState
      ? `${theme.colors.neutral_colors.white}`
      : `rgba(255,255,255,0.2)`
  };
  border-radius: 6px;
  border: 1px solid ${theme.colors.neutral_colors.gray[200]};
  `}
`;

const CustomInput = styled.input<CustomInputPropsType>`
  ${theme.typography.body1};
  width: 328px;
  height: 46px;
  border: none;
  border-bottom: ${({ focusState }) =>
    focusState
      ? `1px solid ${theme.colors.neutral_colors.gray[200]}`
      : '1px solid transparent'};
  padding: 1px 32px 0 0;
  box-sizing: border-box;
  color: ${theme.colors.accent_colors.primary.default};
  caret-color: ${theme.colors.accent_colors.red.default};
  background-color: ${({ isBackgroundColorActive, focusState }) =>
    isBackgroundColorActive || focusState
      ? `${theme.colors.neutral_colors.white}`
      : `transparent`};

  ::placeholder {
    font-family: 'pretendard';
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: ${({ isBackgroundColorActive, focusState }) =>
      isBackgroundColorActive || focusState
        ? `${theme.colors.neutral_colors.gray[600]}`
        : `${theme.colors.neutral_colors.white}`};
  }
`;

const TabContainer = styled.div`
  width: 328px;
`;

const SearchIconWrap = styled.div`
  position: absolute;
  cursor: pointer;
  top: 9px;
  right: 16px;
`;

const ContentBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const DropBoxFooter = styled.div`
  display: flex;
  width: 328px;
  align-items: center;

  border-top: 1px solid ${theme.colors.accent_colors.primary.default};
  justify-content: space-between;
  height: 56px;
`;

const DropBoxFooterLeft = styled.div``;

const DropBoxFooterIcon = styled.div<{ boxSize: string }>`
  width: ${({ boxSize }) => boxSize};
  height: ${({ boxSize }) => boxSize};
  cursor: pointer;
`;

const RefreshBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
`;
