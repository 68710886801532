import { axios } from '@/di-container/axios';
import { GetCartCountGateway } from '@display-component/shared';

export const cartGateway = () => {
  return {
    getCount: new GetCartCountGateway(
      process.env.NEXT_PUBLIC_BASE_API_HOST || '',
      axios,
    ),
  };
};
