import { theme } from '@/styles';
import React from 'react';
import styled from 'styled-components';

const TABLE_DATA = [
  { id: 1, title: '서비스의 명칭', text: '채무지급보증서비스' },
  { id: 2, title: '채무지급 보증기관', text: 'KEB하나은행' },
  {
    id: 3,
    title: '채무지급보증의 범위',
    text: '선불식 전자상거래에 있어 한샘몰이 상품 등의 공급의무를 불이행함으로써 발생하는 소비자 피해',
  },
  {
    id: 4,
    title: '서비스 제공 대상',
    text: `소비자께서 전자상거래시 구매의 안전을 위하여 채무지급보증서비스를 신청하신 경우. 
단, 다음의 경우에는 채무지급보증서비스를 이용할 수 없습니다.
1) 여신전문금융업법 제2조 제3호의 규정에 따른 신용카드로 상품 등의 대금을 지급하는 거래
2) 정보통신망에 의하여 전송되어 제 3자가 배송을 확인 할 수 없는 상품 등을 구매하는 거래
3) 일정기간에 걸쳐 분할되어 공급되는 상품 등을 구매하는 거래
4) 기타 공정거래위원회가 정하여 고시하는 거래`,
  },
  {
    id: 5,
    title: '보증금 한도',
    text: '소비자께서 선불로 지급한 당해 상품의 대금 범위 내',
  },
  {
    id: 6,
    title: '보증금의 지급시기',
    text: '채무지급지급사유가 발생한 경우 지체 없이 지급. 단, 채무보증지급기관은 지급사유를 판단하기 위하여 소비자에게 손해사정과 관련된 재 서류 등을 요청하고 그에 대한 심사를 할 수 있습니다.보증서비스',
  },
];

const BusinessInfoPopup = () => {
  return (
    <Container>
      <Contents>
        <HeadText>
          {`한샘몰은 소비자의 전자상거래 구매안전을 위하여 다음과 같이 채무지급보증서비스를 제공합니다.
(* 법적 근거: 전자상거래 등에서의 소비자보호에 관한 법률 제 24조 등)
`}
        </HeadText>
        <Tbody>
          {TABLE_DATA.map((item) => (
            <Tr key={item.id}>
              <Th>{item.title}</Th>
              <Td>{item.text}</Td>
            </Tr>
          ))}
        </Tbody>
      </Contents>
      <BottomContent>
        <BottomCententText align="center">2013년 05월 15일</BottomCententText>
        <BottomCententText align="left">
          <p>채무지급보증기관</p>
          상호: ㈜KEB하나은행 주소: 서울시 서초구 방배동 131
          <br />
          문의사항:㈜KEB하나은행 방배동지점(전화번호: 02-586-1128 내선 151)
        </BottomCententText>
      </BottomContent>
    </Container>
  );
};

export default BusinessInfoPopup;

const Container = styled.div`
  padding: 0px 40px 40px 40px;
`;

const Contents = styled.div`
  overflow: auto;
  max-height: 350px;
`;

const HeadText = styled.div`
  color: ${theme.colors.accent_colors.primary.default};
  ${theme.typography.body2};
  white-space: pre-wrap;
  margin-bottom: 20px;
`;

const Tbody = styled.table`
  border-collapse: collapse;
  white-space: pre-wrap;
  border-top: 1px solid ${theme.colors.accent_colors.primary.default} !important;
  color: ${theme.colors.accent_colors.primary.default};
`;

const BottomContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BottomCententText = styled.div<{ align: 'center' | 'left' }>`
  align-self: ${({ align }) => align};
  padding: 14px 20px;
  color: ${theme.colors.neutral_colors.gray[700]};
  ${theme.typography.body2};
  p {
    color: ${theme.colors.accent_colors.primary.default};
  }
`;

const Tr = styled.tr`
  border-bottom: 1px solid ${theme.colors.neutral_colors.gray[100]} !important;
`;

const Td = styled.td`
  padding: 14px 20px;
  ${theme.typography.body2};
`;

const Th = styled.th`
  width: 156px;
  padding: 14px 20px;
  box-sizing: border-box;
  text-align: left;
  background-color: ${theme.colors.neutral_colors.gray[50]};
  ${theme.typography.headline7};
`;
