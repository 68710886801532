import React from 'react';
import { IconProps } from './types/Icon.type';

function ArrowDown16Icon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2242 5.57564C13.4585 5.80995 13.4585 6.18985 13.2242 6.42417L8.42417 11.2242C8.18985 11.4585 7.80995 11.4585 7.57564 11.2242L2.77564 6.42417C2.54132 6.18985 2.54132 5.80995 2.77564 5.57564C3.00995 5.34132 3.38985 5.34132 3.62417 5.57564L7.9999 9.95137L12.3756 5.57564C12.61 5.34132 12.9899 5.34132 13.2242 5.57564Z"
        fill={props.fill ?? '#999999'}
      />
    </svg>
  );
}

export default ArrowDown16Icon;
