import { theme } from '@/styles';
import React from 'react';
import styled from 'styled-components';

interface suggestListProps {
  suggestList: string[];
  handleLinkOpen: (value: string) => void;
}

const SuggestList = ({ suggestList, handleLinkOpen }: suggestListProps) => {
  return (
    <Container>
      {suggestList.length !== 0 ? (
        suggestList?.map((item: string) => (
          <Content key={item} onClick={() => handleLinkOpen(item)}>
            {item}
          </Content>
        ))
      ) : (
        <NoneContent>추천 검색어가 없습니다.</NoneContent>
      )}
    </Container>
  );
};

export default SuggestList;

const Container = styled.div`
  width: 100%;
  max-height: 416px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const Content = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  cursor: pointer;
  color: ${theme.colors.accent_colors.primary.default};
  ${theme.typography.body2};
  :hover {
    background-color: ${theme.colors.neutral_colors.gray[50]};
  }
`;

const NoneContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: ${theme.colors.neutral_colors.gray[600]};
  ${theme.typography.body2};
`;
