import React from 'react';
import { IconProps } from './types/Icon.type';

function RankUpIcon(props: IconProps) {
  return (
    <svg
      width={props.boxSize ?? '16'}
      height={props.boxSize ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 5L12.5 11H3.5L8 5Z" fill={props.fill ?? '#121212'} />
    </svg>
  );
}

export default RankUpIcon;
